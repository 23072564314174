jQuery.extend(jQuery.expr[':'], {
	focus: "a == document.activeElement"
});

function initHeader(){
	// show for mobile?
	$('header').removeClass('mobile');
	if ($('header nav').outerHeight() > 30){
		$('header').addClass('mobile show');
	}
	else{
		$('header').addClass('show');
	}
}
function responsiveTable(){
	$('table').wrap( "<div class='table-responsive'></div>" );
}

function truncateText(){
	let el = $('.truncate');
	let elheight = el.data('lines') * 28;
	el.css('height', elheight);
	el.after('<p class="show-hide">Читать далее</p>');
}

function closePopUp(object,event){
	var popup = $(object).closest('.popup');
	event.preventDefault();
	//do not remove class if opened mobile menu
	if(!$("header").hasClass("open-menu")){
		$('body,html').removeClass('overhiden');
	}        
	if (popup.find('form')[0]){
		popup.find('form')[0].reset();
	}
	popup.find('.container').removeClass('show');
	setTimeout(() => {
		popup.removeClass('visible');
	}, 500);
	setTimeout(() => {
		popup.removeClass('show');
	}, 1000);
}

function countSteps(){
	let els = $('#bottom .steps .step .number');
	els.each(function(index) {
		index++;
		$(this).empty().append(index);
	 });
}

$(document).ready(function () {
    // Check show/hide mobile menu
	initHeader();

	//Make tables responsive
	responsiveTable();

	//count steps in action section
	countSteps();
	
	//add current url to hidden form input
	var curUrl = $(location).attr('href');
	$("input.curURL").val(decodeURI(curUrl));

	//find and truncate	.truncate elements + untruncate on click .show-hide
	truncateText();	
	$('.show-hide').click(function(){		
		if ($(this).html() == "Читать далее"){
			$(this).html("Свернуть").addClass('open');			
		}
		else{
			$(this).html("Читать далее").removeClass('open');
			$('html, body').animate({
				scrollTop: $(this).prev('.truncate').offset().top - 30
			  }, 300);
		}
		$(this).prev('.truncate').toggleClass('show');
	});

    // show | hide mobile menu
	$('.mobile-burger-icon,.mobile-menu-close-space').on('click',function(){
		$('header').toggleClass('open-menu');
		$('body').toggleClass('overhiden');
		let langBlock = $('header .lang-block');
		let menuShowDelay = 250;
		let menuItemsCount = $('header nav a').length;
		let callDelay = (menuItemsCount*menuShowDelay)+menuShowDelay;
		if ($('header').hasClass('open-menu')){
			langBlock.appendTo('header .wrapper.wide');
			$('header nav a').each(function(i){
				var row = $(this);
				setTimeout(function() {
					row.toggleClass('show', !row.hasClass('show'));
				}, menuShowDelay*i);
			});
			setTimeout(function() {
				$('header .call').toggleClass('show', !$(this).hasClass('show'));
			}, callDelay);			
		}
		else{
			langBlock.appendTo('header .right');
			$('header nav a, header .call').removeClass('show');
		}
	});

    // open pop-up
	$('.popup-show').click(function (e) { 
		let popUpId, popUpName, popUpTitle, popUpSub, popup, popUpBtn;
		e.preventDefault();
		$('body,html').addClass('overhiden');
		popUpId = $(this).data('popup');
		popUpName = $(this).data('popup-name');
		popUpTitle = $(this).data('popup-title');
		popUpSub = $(this).data('popup-subtitle');
		popUpBtn = $(this).data('popup-btn');
		if (popUpBtn == '' || popUpBtn == undefined || popUpBtn == null){popUpBtn = "Ок"}
		popup = $('[data-id="'+popUpId+'"]');
		popup.find('.h2').html(popUpTitle);
		popup.find('input[type="submit"]').val(popUpBtn);
		popup.find('.form-name').val(popUpName);
		popup.find('.subtitle').html(popUpSub);
		popup.addClass('show visible');
		setTimeout(() => {
			popup.find('.container').addClass('show');
		}, 500);
	});

	// close pop-up
	$('.popup').on('click', '.close, .btn.clear', function(e){
		closePopUp(this,e);
	});
	$('.popup').on('click', function(e){
		if (e.target === this){
			closePopUp(this,e);
		}		
	});

	// animate elements on load
	$('.animated').each(function(){
		var imagePos = $(this).offset().top;
		var topOfWindow = $(window).scrollTop();
		var windowHeight = $(window).height();
		if($(this).data("anim-begin")){
			var heightBegin = $(this).data("anim-begin");
		}
		else{
			heightBegin = 0.9;
		}
		var beginView = windowHeight*heightBegin;
		var classAnimate = $(this).data("anim");
		if (imagePos < topOfWindow+beginView) {
		  $(this).addClass(classAnimate+' done');
		}
	});


	//click on mobile cases
	// $(".case").on('click', 'a',function(e){				
	// 	if($('body').hasClass('touch')){
	// 		if(!$(this).hasClass('focus')){
	// 			$('.case a').removeClass('focus');
	// 			$(this).addClass('focus');
	// 			e.preventDefault();
	// 		}		
	// 	}		
	// });
	// $(".case a").focusout(function(){
	// 	$(this).removeClass('focus');
	// });
}); //END READY

$(window).scroll(function() {
	//animate elements on scroll
	$('.animated').each(function(){
		var imagePos = $(this).offset().top;
		var topOfWindow = $(window).scrollTop();
		var windowHeight = $(window).height();
		if($(this).data("anim-begin")){
				var heightBegin = $(this).data("anim-begin");
		}
		else{
				heightBegin = 0.9;
		}
		var beginView = windowHeight*heightBegin;
		var classAnimate = $(this).data("anim");
		if (imagePos < topOfWindow+beginView) {
			$(this).addClass(classAnimate+' done');
		}
	});
	
}); // END scroll handlers

$(window).on('orientationchange', function(){
	// Check show/hide mobile menu
	initHeader();
});

$(window).on('resize', function(){
	// Check show/hide mobile menu
	initHeader();
});

window.addEventListener('touchstart', function onFirstTouch() {
	// we could use a class
	document.body.classList.add('touch');  
	// we only need to know once that a human touched the screen, so we can stop listening now
	window.removeEventListener('touchstart', onFirstTouch, false);
  }, false);

$(document).on('af_complete', function(event, response) {
	var form = response.form;
    // Если у формы определённый id
    if (form.attr('id') == 'pu') {
		var popup = form.closest('.popup');
		if(!$("header").hasClass("open-menu")){
			$('body,html').removeClass('overhiden');
		}        
		if (popup.find('form')[0]){
			popup.find('form')[0].reset();
		}
		popup.find('.container').removeClass('show');
		setTimeout(() => {
			popup.removeClass('visible');
		}, 500);
		setTimeout(() => {
			popup.removeClass('show');
		}, 1000);		
    }
    // Иначе печатаем в консоль весь ответ
    else {
        console.log(response)
    }
});
